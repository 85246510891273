@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Croissant+One:regular);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: sans-serif, Arial, Helvetica;
}
.croissant-One_font {
  font-family: "Croissant One";
}
/* scrollbar */
::-webkit-scrollbar {
  /* overflow: hidden; */
  visibility: hidden;
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: 5px 5px 10px #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #fff2;
}
::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 10px;
  background: #00ff9f;
}
::-webkit-scrollbar-horizontal {
  height: 10px;
}

/* Loding */
.loading-conatiner {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.loading {
  animation: spinner 0.6s linear infinite;
  border-top-color: #00ff9f;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
/* Nav Link */
.navLink.active {
  background: #00ff9f;
}
.navLink.active svg {
  color: #000;
}
/* cd */
.resume-item::before {
  position: absolute;
  top: 9px;
  left: -9px;
  border: 3px solid #a6a6a6;
  border-radius: 50px;
  background: #a6a6a6;
  width: 16px;
  height: 16px;
  content: "";
}

/* Porject Card */
.card {
  transition: 0.5s all;
}
